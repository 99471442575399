import React, { Component } from 'react'
import axios from 'axios'
import findIndex from 'lodash/findIndex';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { sharedLoggerApi } from 'shared-helpers';

// api
import fundingApi from "/src/apis/fundingApi";
import taxonomyApi from '/src/apis/taxonomyApi';

// constants
import { API_URL_NEW } from '/src/lib/endpoints';
import { ALLOWED_QUERY_OPERATOR, FUNDING_STATUS, RECORD_COUNT_DEFAULT, FILTER_LAYOUT_TYPES } from '/src/lib/constants';
import { filters_data } from '/src/components/reusable/directories/SidebarFilters';

// helpers
import { checkEmailStatus } from '/src/lib/helpers';
import { cloneDeep, isEmpty } from 'lodash';

const loggerApi = sharedLoggerApi;
const componentName = 'DefaultComponent';



class DefaultComponent extends Component {

    componentDidMount() {
        if (this.filterValues) this.filterValues('')
    }

    showFiltersValues = (name) => {
        this.setState({ activeBlock: name })
    };

    changeLimit = () => {
        let { limit, page, tempFilter, count } = this.state;
        page = (parseInt(page, 10));
        tempFilter.isPageChanged = true;

        // Only increment the page if there are more records exists
        if (count > (limit * page)) {
            page = page + 1;
            this.setState({ page }, () => this.filterValues(tempFilter, true));
        }
    };

    handleFollow = async (id, emailStatus) => {
        if (!checkEmailStatus(emailStatus)) {
            return
        }

        let { data } = this.state;
        try {
            const entityType = this.entityType.toLowerCase();

            const config = {
                method: 'POST',
                url: `${API_URL_NEW}/${entityType}/${id}/follow`
            };
            const response = await axios(config);

            if (response.data.status === 201) {
                const index = findIndex(data, { id });

                if (index > -1) {
                    if (data[index].is_user_following > 0) {
                        data[index].is_user_following = 0
                    } else {
                        data[index].is_user_following = 1;
                    }

                    this.setState({
                        success: true,
                        data: data,
                        addtofavourites: data[index].is_user_following,
                        favorite: data[index].name
                    });
                }
            }
        } catch (error) {
            loggerApi.logError(id, componentName, error)
        }
    }

    removeOneFilter = (name, value) => {
        let filter = {
            ...this.state.filters
        };
        if (filter[name] && Array.isArray(filter[name]) && filter[name].length > 1) {
            filter[name].splice(filter[name].indexOf(value), 1);
        } else {
            delete filter[name];
        }


        if (!Object.keys(filter).length) {
            filter = '';
        }

        this.filterValues(filter);
    };

    handleChange = (event) => {
        let obj = {};

        let name, value;
        if (event.name && event.name !== undefined) {
            name = event.name;
            value = event.value;
        } else {
            name = event.target.name;
            value = event.target.value;
        }
        obj[name] = value;

        return new Promise((resolve, reject) => {
            this.setState(obj, () => { resolve() });
        })

    }

    addFilter(event) {
        if (!this.props.user.is_loggedin) {
            this.Register()
        }
        else if (this.props.user.roles.includes(8)) {
            this.setState({ popup: true })
        }
        /*else {
            if (!this.state.popup) {
                this.setState({ popup: true, isMobileFilters: false });
            }
        } */
        else {
            this.setState({ corporate: true })
        }
    }

    applyFilter(event) {
        let tempFilter = { ...this.state.tempFilter };
        let filter = { ...this.state.filters };
        for (let name in tempFilter) {
            let temp = [];
            for (let i = 0; i < tempFilter[name].length; i++) {
                if (tempFilter[name][i].name !== 'title') {
                    if (tempFilter[name][i].name === 'filter') {
                        filter['title'] = tempFilter[name][i].value;
                    } else {
                        if (filter[name]) {
                            temp = filter[name];
                        } else {
                            temp = [];
                        }
                        if (temp.indexOf(tempFilter[name][i].value) === -1) {
                            temp.push(tempFilter[name][i].value);
                        }

                        filter[name] = temp;
                    }
                } else {
                    filter[tempFilter[name][i].name] = tempFilter[name][i].value;
                }
            }
        }

        this.setState({ popup: false });
        if (this.filterValues) this.filterValues(filter);
    }

    closePopup(event) {
        this.setState({ popup: false, corporate: false })
        //this.setState({ showaddloc: false })
        document.body.style.overflowY = 'scroll'

        // reset filters display
        this.state.activeBlock = 'keyword'
        this.state.filtercontent_title = 'Keywords'
        this.state.checkbox_filtername = 'keyword'
        if (this.state.filtersPopup) {
            this.state.filtersPopup.map(item1 => {
                this.state[`show_${item1.shortname}`] = false
                this.state[`show${item1.shortname}`] = false
                this.state[`showadd${item1.shortname}`] = false
                this.state[`${item1.shortname}text`] = ''
            })
        }
        this.calculateFiltersCount()
    }

    changeOrder(obj) {
        if (!this.props.user.is_loggedin) {
            return this.Login();
        }

        //checking directory access
        if (this.props.user.roles.indexOf(8) < 0) {
            return this.setState({ corporate: true, showsortlist: false });
        }

        obj.field === undefined && obj.order === 'none' ? this.state.tempFilter.order = '' :
            this.state.tempFilter.order = { field: obj.field, order: obj.order }
        this.filterValues(this.state.tempFilter)
        this.setState({ sort: obj.name, showsortlist: false })
    }

    Forgot(event) {
        this.setState({ add: true, login: false, register: false, forgot: true });
    }

    Login(event) {
        this.setState({ add: true, login: true, register: false, forgot: false });
    }

    Register(event) {
        this.setState({ add: true, login: false, register: true, forgot: false });
    }

    // @so:Begin Shared among all filters

    handlePopupFilterSelect(item) {
        let { filtersPopup, filterDefinition } = this.state;
        let tempObj = {};

        tempObj['activeBlock'] = item.shortname;
        tempObj['filtercontent_title'] = item.name;

        filtersPopup.map(item1 => {
            tempObj[`show_${item1.shortname}`] = false
            tempObj[`show${item1.shortname}`] = false  // reset show inputfield
            tempObj[`showadd${item1.shortname}`] = false  // reset show add button
            tempObj[`${item1.shortname}text`] = ''     // reset the inputfield
        });


        if (this.state.filterDefinition[item.shortname][0].type === 'list') {
            tempObj.checkbox_filtername = item.shortname;

            if (item.shortname !== 'keyword') {
                filterDefinition[item.shortname] = this.state[`${item.shortname}store`]
                tempObj['filterDefinition'] = filterDefinition;
            }

        } else {
            tempObj[`show_${item.shortname}`] = true;
            tempObj['checkbox_filtername'] = '';
        }

        this.setState(tempObj);
    }

    handleKeywordEnter = (e, key = 'keyword') => {
        const keyword = this.state[key];
        if (e.keyCode === 13 && keyword.trim() !== '') {
            this.handleApplyKeywordFilter(key)
        }
    }

    debouncedKeywordEnter = debounce((e, key = 'keyword') => {
        const keyword = this.state[key];
        if (keyword.trim() !== '') {
            this.handleApplyKeywordFilter(key)
        }
    }, 2000)

    handleApplyKeywordFilter = (key) => {
        const keyword = this.state[key];
        let { tempFilter } = this.state;
        let tempObj = {};

        if (!this.props.user.is_loggedin) {
            tempFilter[key].push(keyword)
            tempObj['tempFilter'] = tempFilter;

            this.setState(tempObj, () => {
                this.calculateFiltersCount();
            });
        }
        else if (!this.props.user.roles.includes(8)) {
            if (this.state.numoffilter >= 1 || this.state.tempFilter[key].length >= 1) {
                this.setState({ corporate: true })
            }
            else {
                tempFilter[key].push(keyword)
                tempObj['tempFilter'] = tempFilter;

                this.setState(tempObj, () => {
                    this.calculateFiltersCount();
                });
            }
        }
        else {
            if (this.state.tempFilter[key].indexOf(keyword) === -1) {
                tempFilter[key].push(keyword)
                tempObj['tempFilter'] = tempFilter;

                this.setState(tempObj, () => {
                    this.calculateFiltersCount();
                });
            }
        }

        this.setState({ [key]: '' })
    }

    handleChangeInput = (value, str) => {
        let tempObj = {};
        tempObj[str] = value;

        if (str === 'year' && !value.match(/^\d*$/)) {
            //do nothing
        } else {
            if (str === 'location_id') {
                if (value.length > 2) {
                    const URL = `${API_URL_NEW}/location?filters=name^sub:${this.state.location_id}&fields=id^,name`;
                    axios.get(URL).then(response => {
                        this.setState({
                            location_ids: response.data.data,
                            location_idstore: response.data.data
                        })
                    })
                }
            } else {
                // filter taxonomy list to show ops that match the user's search
                const taxonomyList = this.state[`${str}store`];
                if (!taxonomyList) {
                    if (str !== 'program') {
                        console.log("err no taxo list for " + `${str}store`);
                    }
                    return;
                }

                tempObj[`${str}s`] = taxonomyList.filter(item => {
                    return item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                });
            }
        }

        this.setState(tempObj);
    }

    handleChangeText = (value, str) => {
        let { filterDefinition } = this.state;
        let tempObj = {};

        if (str !== 'keyword') {

            if (value !== '') {
                tempObj[`show${value}`] = true;
            }

            const arr = this.state[`${str}store`].filter(item => {
                return item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
            });

            filterDefinition[str] = arr
            tempObj[`${str}text`] = value;
            tempObj['filterDefinition'] = filterDefinition;

            this.setState(tempObj);
        }
    }

    handleItemSelect = (obj, str, id) => {
        let { tempFilter } = this.state;
        let tempObj = {};
        let tempCompare = { id: obj.id, name: obj.name }
        if (findIndex(tempFilter[str], tempCompare) === -1) {
            tempFilter[str].push({ id: obj.id, name: obj.name });
            tempObj['tempFilter'] = tempFilter;

            this.setState(tempObj, () => {
                this.calculateFiltersCount()
            });
        }
    }

    handleMultiSelectFilter = (arr, str) => {
        let { tempFilter } = this.state;
        let tempObj = {};

        tempFilter[str] = arr

        tempObj['tempFilter'] = tempFilter;

        this.setState(tempObj, () => {
            this.calculateFiltersCount()
        });
    }

    handleRemoveItem(index, str) {
        let { tempFilter } = this.state;
        let tempObj = {};

        tempFilter[str].splice(index, 1)
        tempObj['tempFilter'] = tempFilter;

        this.setState(tempObj);
    }

    handleSelectFilter = (obj, str) => {

        let { tempFilter } = this.state;
        let tempObj = {};
        const typeObj = obj.type ? { type: obj.type } : {}
        let tempCompare = { id: obj.id, name: obj.name, ...typeObj }

        if (this.state.numoffilter >= 1 || this.state.tempFilter[str].length >= 1) {

            if (this.props.user.roles.includes(8) || this.state.numoffilter <= 4 || this.state.tempFilter[str].length <= 4) {
                if (findIndex(this.state.tempFilter[str], tempCompare) === -1) {

                    tempFilter[str].push({ id: obj.id, name: obj.name, ...typeObj });

                    tempObj['tempFilter'] = tempFilter;
                    // Removing re-assigning to avoid glitch in frontend
                    // tempObj[`${str}s`] = this.state[`${str}store`];
                    tempObj[str] = '';

                    this.setState(tempObj, () => {
                        this.calculateFiltersCount();
                    });
                }
            } else {
                this.setState({ corporate: true });
            }
        } else {
            if (findIndex(this.state.tempFilter[str], tempCompare) === -1) {
                tempFilter[str].push({ id: obj.id, name: obj.name, ...typeObj });

                tempObj['tempFilter'] = tempFilter;
                // Removing re-assigning to avoid glitch in frontend
                // tempObj[`${str}s`] = this.state[`${str}store`];
                tempObj[str] = '';

                this.setState(tempObj, () => {
                    this.calculateFiltersCount();
                });
            }
        }
    }

    handleRemoveFilter = (index, str) => {
        let { tempFilter } = this.state;
        let tempObj = {};

        tempFilter[str].splice(index, 1);

        tempObj['tempFilter'] = tempFilter;
        tempObj[`${str}s`] = this.state[`${str}store`];

        this.setState(tempObj, () => {
            this.calculateFiltersCount()
        });
    }

    // clear selected options for 1 specific filter
    handleClearAllFilters = (str) => {
        let { tempFilter } = this.state;
        let tempObj = {};

        tempFilter[str] = [];
        tempObj[str] = '';
        tempObj[`${str}s`] = this.state[`${str}store`];
        tempObj['tempFilter'] = tempFilter;

        this.setState(tempObj, () => {
            this.calculateFiltersCount()
        });
    }

    // clear all directory filters
    clearFilter = () => {

        const { user } = this.props;
        const { entityType, state } = this;
        const YEAR = parseInt(moment().format('YYYY'));

        // set limit
        let limit = null;
        if (entityType === 'EXITS' || entityType === 'FUNDING' || entityType === 'FUND' || user.is_loggedin) {
            limit = RECORD_COUNT_DEFAULT.default;
        } else {
            limit = RECORD_COUNT_DEFAULT.loggedOut;
        }

        // set sort & order
        let sort = '';
        let order = {};
        if (entityType === 'STARTUP') {
            sort = 'Recently Funded';
            order = { field: "last_date_raised", order: "desc" }
        } else if (entityType === 'INVESTOR') {
            sort = '# of Investments: High to Low';
            order = { field: "number_of_investments", order: "desc" }
        } else if (entityType === 'EXITS') {
            sort = 'Acquisition Date: New to Old';
            order = { field: "date_raised", order: "desc" };
        } else if (entityType === 'FUNDING') {
            sort = 'None';
            order = { field: "date_raised", order: "desc" }
        } else if (entityType === 'FUND') {
            sort = 'Date Funded: New to Old';
            order = { field: "funding_date_raised", order: "desc" }
        } else if (entityType === 'PEOPLE') {
            sort = 'total Amount Raised'
            order = { field: "total_amount_raised", order: "desc" }
        } else if (entityType === 'PRIVATE EQUITY') {
            sort = 'Date Closed: New to Old'
            order = { field: "pe_round_date", order: "desc" }
        }

        // set default filters when clear button is clicked
        let defaultFilters = {};
        if (state.isClientDirectory) {
            defaultFilters = cloneDeep(state.defaultFilters)
        }

        this.setState({
            activeBlock: 'keyword',
            active_checked: '',
            breakdownamountMinSuffix: '000',
            breakdownamountMaxSuffix: '000',
            checkbox_filtername: 'keyword',
            city: '',
            date_maxValue: YEAR,
            date_minValue: 1980,
            employee_maxValue: 450,
            employee_maxdisplay: '450',
            employee_minValue: 0,
            employee_mindisplay: '0',
            exportSelectedStartups: [],
            filtercontent_title: 'Keywords',
            funded_selected: 'All',
            funding_stage: '',
            fundingStatus: false,
            gender: '',
            industry: '',
            investor: '',
            investorKeyword: '',
            isShowExportBtn: false,
            keyword: '',
            last_stage_raised: '',
            limit,
            loc: '',
            location: '',
            numoffilter: 0,
            popup: false,
            presentation_checked: '',
            revenue_checked: '',
            roundsRaised_minValue: 0,
            roundsRaised_maxValue: 100,
            sec_industry: '',
            show_active: false,
            show_date: false,
            show_employee: false,
            show_fundraising: false,
            show_revenue: false,
            show_startup: false,
            sort,
            stage: '',
            startup_minValue: 0,
            startup_maxValue: 300,
            startup_mindisplay: '0',
            startup_maxdisplay: '300',
            sub_industry: '',
            totalamountMaxSuffix: '000',
            totalamountMinSuffix: '000',
            type: '',
            year_checked: '',
            yearsOfExit_minValue: 0,
            yearsOfExit_maxValue: 20,
            nationality: '',
            job: '',
            degree: '',
            entityName: '',
            isFounder: false,
            isInvestor: false,
            isSelectAllEntities: false,
            arrSelectedEntities: [],
            regionFocusLocation: '',
            investedInTypeFilter: '',
            entity_relation: '',
            program: '',
            investmentLocation: '',
            investmentRegion: '',
            investedInPrimaryIndustry: '',
            investmentFundingStage: '',
            company_type: '',
            isClearAllFilter: true,
            tempFilter: {
                active: '',
                acquirerRegion: [],
                acquirerLocation: [],
                basic: [],
                breakdownamount: '',
                city: [],
                date: '',
                employee: '',
                filter_date: '',
                funding_stage: [],
                funding_verification_status: false,
                geoFocusLocation: [],
                regionFocusLocation: [],
                gender: '',
                industry: [],
                institution: [],
                investment_date: '',
                investmentVehicle: [],
                investor: [],
                is_funded: '',
                keyword: [],
                investorKeyword: [],
                last_date_raised: '',
                last_stage_raised: [],
                location: [],
                number_of_rounds_raised: '',
                order,
                prevfundraise: [],
                previnvestors: [],
                primary_industry: [],
                region: [],
                revenue: '',
                roundsRaised: '',
                sec_industry: [],
                stage: [],
                startup: '',
                sub_industry: [],
                totalamount: '',
                type: [],
                verification_status: [],
                yearsOfExit: '',
                job: [],
                degree: [],
                nationality: [],
                entityName: [],
                isFounder: false,
                isInvestor: false,
                numberOfFunds: '',
                amountraised: '',
                investedInTypeFilter: [],
                entity_relation: [],
                location: [],
                primary_industry: [],
                program: [],
                investmentRegion: [],
                investmentLocation: [],
                investedInPrimaryIndustry: [],
                investmentFundingStage: [],
                company_type: '',

                //  filters for custom directory
                ...defaultFilters
            },
        }, () => {

            if (entityType === 'STARTUP' || entityType === 'EXITS') {
                this.getSubIndustries();
            }

            if (!isEmpty(defaultFilters)) {
                this.filterValues(this.state.tempFilter)
            } else if (this.filterValues) {
                this.filterValues('');
            }
        });
    }

    handleSearchFilters = () => {
        let { tempFilter } = this.state;
        let tempObj = {};

        if (!this.props.user.is_loggedin) {
            if (this.state.keyword !== '') {
                tempFilter['keyword'].push(this.state.keyword);
                tempObj['tempFilter'] = tempFilter;
                tempObj['keyword'] = '';
            }

            this.setState(tempObj, () => {
                this.filterValues(this.state.tempFilter);
                this.calculateFiltersCount();
            });
        }

        else {
            if (this.state.tempFilter.keyword.indexOf(this.state.keyword) === -1 && this.state.keyword !== '') {
                tempFilter['keyword'].push(this.state.keyword);
                tempObj['tempFilter'] = tempFilter;
                tempObj['keyword'] = '';
            }

            this.setState(tempObj, () => {
                this.filterValues(this.state.tempFilter);
                this.calculateFiltersCount();
            });
        }
    }

    //@so:End Shared among all filters

    //@so:Begin shared among Investors/Corporates/Enablers

    handleNumofStartupChange(value) {
        let tempFilter = this.state.tempFilter;
        let tempObj = {};
        const minValue = value[0];
        const maxValue = value[1];

        tempFilter['startup'] = {
            min: minValue,
            max: maxValue
        };

        tempObj['startup_minValue'] = minValue;
        tempObj['startup_maxValue'] = maxValue;
        tempObj['startup_maxdisplay'] = maxValue;
        tempObj['startup_mindisplay'] = minValue;
        tempObj['tempFilter'] = tempFilter;

        this.setState(tempObj);
    }

    //@so: End shared among Investors/Corporates/Enablers

    handleKeyword(e) {
        let { filters, filterFields } = this.state
        const keyCode = e.keyCode

        if (keyCode && keyCode === 13) {

            if (this.state.keyword !== '') {
                filters.map(item => {
                    if (item.key === 'keyword' && item.value.indexOf(this.state.keyword) === -1) {
                        item.value.push(this.state.keyword)
                    }
                })

                filterFields.map(item => {
                    if (item.key === 'keyword' && item.value.indexOf(this.state.keyword) === -1) {
                        item.value.push(this.state.keyword)
                    }
                })

                this.setState({
                    filters: filters,
                    filterFields: filterFields,
                    keyword: '',
                }, () => {
                    this.calculateFiltersCount()
                    this.filterValues()
                })
            }
        }
    }

    handleSelect(obj, str) {
        let { filters, filterFields } = this.state;
        let tempObj = {};

        filters.map(item => {
            if (item.key === str && item.value.indexOf(obj.name) === -1) {

                item.value.push(obj.name)
                filterFields.map(item => {
                    if (item.key === str) {
                        item.value.push(obj.id)
                    }
                });
            }
        });

        tempObj['filters'] = filters;
        tempObj['filterFields'] = filterFields;
        tempObj[`${str}s`] = this.state[`${str}store`];
        tempObj[str] = '';

        this.setState(tempObj, () => {
            this.calculateFiltersCount()
        });
    }

    handleRemove(index, str) {
        let { filters, filterFields } = this.state;
        let tempObj = {};

        filters.map(item => {
            if (item.key === str) {

                item.value.splice(index, 1)
                filterFields.map(item => {
                    if (item.key === str) {
                        item.value.splice(index, 1)
                    }
                });
            }
        });

        tempObj['filters'] = filters;
        tempObj['filterFields'] = filterFields;

        this.setState(tempObj, () => {
            if (str === 'keyword' || str === 'name') {
                this.filterValues()
                this.calculateFiltersCount()
            }
        });
    }

    handleClearAll(str) {
        let { filters, filterFields } = this.state;
        let tempObj = this.state;

        filters.map(item => item.value = [])

        // this.state[`${str}s`] = this.state[`${str}store`]

        const index = filterFields.findIndex(item => {
            return item.key === str
        });

        filterFields[index].value = [];

        tempObj['filters'] = filters;
        tempObj['filterFields'] = filterFields;
        tempObj[`${str}s`] = this.state[`${str}store`];

        this.setState(tempObj, () => {
            this.filterValues();
            this.calculateFiltersCount();
        });
    }

    handleSearch() {
        let { filters, filterFields } = this.state;
        let tempObj = {};

        if (this.state.keyword !== '') {
            filters.map(item => {
                if (item.key === 'keyword' && item.value.indexOf(this.state.keyword) === -1) {

                    item.value.push(this.state.keyword);
                    filterFields.map(item => {
                        if (item.key === 'keyword') {
                            item.value.push(this.state.keyword)
                        }
                    });
                }
            });

            tempObj['filters'] = filters;
            tempObj['filterFields'] = filterFields;
            tempObj['keyword'] = '';
        }

        this.setState(tempObj, () => {
            this.filterValues();
            this.calculateFiltersCount();
        });
    }

    /* success popup for directories */
    /* used after successful data export */
    showSuccessPopup = (message = "") => {
        this.setState({
            popupSuccessMsg: message
        }, () =>
            $('#successModal').modal({
                backdrop: 'static',
                keyboard: true,
                show: true
            })
        );
    }

    /* for startup directory - collapsible funding row */
    getFundingDataForSubRow = (entity_data, verificationStatus = false) => {
        const entity_id = entity_data.id;
        const limit = 4;
        const page = 1;
        const entityType = entity_data.entity_type ? entity_data.entity_type.toLowerCase() : 'startup';

        // don't call data if it's already present from previous calls
        if (this.state["funding_rounds_" + entity_id]) {
            return;
        }

        let arrFilters = [];
        if (verificationStatus) {
            arrFilters.push({
                key: 'verification_status_filter',
                value: [FUNDING_STATUS.verified],
                op: ALLOWED_QUERY_OPERATOR.equal
            });
        }

        fundingApi.getFundingData(entity_id, entityType, limit, arrFilters, "", page, "directory").then(res => {
            if (res.rounds) {
                const data = {
                    rounds: res.rounds,
                    show_profile_link: res.totalRecords > limit ? true : false
                }
                this.setState({
                    ["funding_rounds_" + entity_id]: data
                });
            }
        })

        // Remove any hover component
        $(".popover").remove();
    }

    /*
        method to get locations list for directories
        when region is selected => select countries in that region
        when region is de-selected => de-select countries in that region
    */

    getRegionLocations = async (strLocation = 'location', strRegion = 'region', operationType, region_id) => {

        if (operationType === "add") {
            this.addLocationsFromRegion(strLocation, strRegion, region_id);
        }

        // remove locations from a removed region
        if (operationType === "remove") {

            let { tempFilter } = this.state;
            let { locationsInRegion } = this.state;
            let selectedRegions = tempFilter[strRegion].map(region => {
                return region.id;
            })

            let tempLocation = [];
            let temp = [];

            if (locationsInRegion) {
                locationsInRegion.forEach((loc, i) => {
                    if (loc.type === strRegion && !selectedRegions.includes(loc.region_id)) {

                        const idsToRemove = loc.locs.map(location => {
                            return location.id;
                        })

                        tempFilter[strLocation].map(item => {
                            if (!idsToRemove.includes(item.id)) {
                                tempLocation.push(item);
                            }
                        })

                    } else {
                        temp.push(loc);
                    }
                });
            }

            tempFilter[strLocation] = tempLocation;

            this.setState({
                locationsInRegion: temp,
                tempFilter
            });

        }

    }

    addLocationsFromRegion = (strLocation = 'location', strRegion = 'region', region_id) => {

        const objFilters = { ...this.state.tempFilter };
        const regionFilter = { key: 'region', value: [region_id], op: ALLOWED_QUERY_OPERATOR.equal };

        taxonomyApi.getLocations(300, regionFilter).then(
            response => {

                // selected locations: ones that match region filter + ones previously selected
                objFilters[strLocation].push(...response);

                // remove duplicates
                const selected = objFilters[strLocation].filter(function ({ id }) {
                    return !this[id] && (this[id] = id)
                }, {})

                // save the locs in this region, so they can be removed when region is deselected
                let { locationsInRegion } = this.state;
                locationsInRegion = (locationsInRegion || []);
                locationsInRegion.push({
                    type: strRegion, // used to differentiate b/w loc filter and acq loc
                    locs: response,
                    region_id
                })

                this.setState(prevState => ({
                    tempFilter: {
                        ...prevState.tempFilter,
                        [strLocation]: selected,
                    },
                    locationsInRegion
                }));

            }
        );

    }

    // call taxonomy api for directory filters
    getTaxonomyData = () => {

        let promiseArray = [];
        let { filterDefinition } = this.state;

        const { entityType } = this;
        let filters = null;
        if (entityType === 'STARTUP') {
            filters = filters_data[FILTER_LAYOUT_TYPES.startups];
        } else if (entityType === 'INVESTOR') {
            filters = filters_data[FILTER_LAYOUT_TYPES.ecosystem];
        } else if (entityType === 'FUNDING') {
            filters = filters_data[FILTER_LAYOUT_TYPES.fundingRounds]
        } else if (entityType === 'EXITS') {
            filters = filters_data[FILTER_LAYOUT_TYPES.exits]
        } else if (entityType === 'PRIVATE EQUITY') {
            filters = filters_data[FILTER_LAYOUT_TYPES.privateEquity]
        }
        if (!filters) { return; }

        // get list of filters for directory
        let filterList = [];
        filters.map(formGroup => {
            formGroup.filters.map(filter => {
                filterList = [...filterList, ...Object.keys(filter)]
            })
        })

        // make taxonomy api calls
        const maxLimit = 300;

        if (filterList.includes('SecondaryIndustryFilter')) {
            const industryPromise = taxonomyApi.getIndustries(maxLimit).then(
                response => {
                    let industryList = [];
                    response.map((item) => {
                        industryList.push({ id: item.id, name: item.name, type: 'list' });
                    });
                    filterDefinition.industry = industryList;
                    this.setState({
                        sec_industrystore: industryList,
                        sec_industrys: response,
                        filterDefinition
                    });
                }
            );
            promiseArray.push(industryPromise)
        }

        if (promiseArray.length > 0) {
            return promiseArray;
        }

    }

    /**
     * Method used to select the entity directory record which will be exported
     * @param {*} event         Input select event
     * @param {*} entityId      Entity ID
     */
    handleCheckBox = (event, entityId) => {
        event.preventDefault();
        let { arrSelectedEntities } = this.state;

        // add/remove entity from selected list
        if (arrSelectedEntities.includes(entityId)) {
            arrSelectedEntities = arrSelectedEntities.filter(id => id != entityId);
        } else {
            arrSelectedEntities.push(entityId);
        }

        this.setState({ arrSelectedEntities });
    }

    /**
     * Method used to select all the entities in the table to be exported
     */
    handleCheckAllBoxes = () => {
        const { isSelectAllEntities, data } = this.state;

        let arrSelectedEntities = [];
        if (!isSelectAllEntities) {
            arrSelectedEntities = data.map(entity => entity.id);
        }

        this.setState({
            arrSelectedEntities,
            isSelectAllEntities: !isSelectAllEntities,
        });
    }
}

export default DefaultComponent;
