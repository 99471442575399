import React, { Component } from 'react';
import loadable from '@loadable/component';
import moment from 'moment';
import { numOfEmployees } from '../../../../src_temp/components/Single-SICE/constants/formOptions';

// const
import { FILTER_LAYOUT_TYPES, CLEAR_ALL_FILTER_BTN_CLASSNAME } from '/src/lib/constants';

// helpers
import { amountFormatter } from '/src/lib/helpers';
import { cloneDeep } from 'lodash';

// filters
const LocationFilter = loadable(() => import(/* webpackChunkName: "LocationFilter" */ './LocationFilter'));
const AcquirerLocationFilter = loadable(() => import(/* webpackChunkName: "AcquirerLocationFilter" */ './AcquirerLocationFilter'));
const KeywordSearch = loadable(() => import(/* webpackChunkName: "KeywordSearch" */ './KeywordSearch'));
const IndustryFilter = loadable(() => import(/* webpackChunkName: "IndustryFilter" */ './IndustryFilter'));
const DevStageFilter = loadable(() => import(/* webpackChunkName: "DevStageFilter" */ './DevStageFilter'));
const RevenueFilter = loadable(() => import(/* webpackChunkName: "RevenueFilter" */ './RevenueFilter'));
const ActiveFilter = loadable(() => import(/* webpackChunkName: "ActiveFilter" */ './ActiveFilter'));
const FundedFilter = loadable(() => import(/* webpackChunkName: "FundedFilter" */ './FundedFilter'));
const DateFilter = loadable(() => import(/* webpackChunkName: "DateFilter" */ './DateFilter'));
const EmployeeFilter = loadable(() => import(/* webpackChunkName: "EmployeeFilter" */ './EmployeeFilter'));
const TotalAmountRaisedFilter = loadable(() => import(/* webpackChunkName: "TotalAmountRaisedFilter" */ './TotalAmountRaisedFilter'));
const ClaimFilter = loadable(() => import(/* webpackChunkName: "ClaimFilter" */ './ClaimFilter'));
const BusinessTypeFilter = loadable(() => import(/* webpackChunkName: "BusinessTypeFilter" */ './BusinessTypeFilter'));
const CompanyTypeFilter = loadable(() => import(/* webpackChunkName: "CompanyTypeFilter" */ './CompanyTypeFilter'));
const TypeFilter = loadable(() => import(/* webpackChunkName: "TypeFilter" */ './TypeFilter'));
const NoOfStartupsFilter = loadable(() => import(/* webpackChunkName: "NoOfStartupsFilter" */ './NoOfStartupsFilter'));
const FundingVerificationFilter = loadable(() => import(/* webpackChunkName: "FundingVerificationFilter" */ './FundingVerificationFilter'));
const GenderFilter = loadable(() => import(/* webpackChunkName: "GenderFilter" */ './filters/GenderFilter'));
const InvestorKeywordSearch = loadable(() => import(/* webpackChunkName: "InvestorKeywordSearch" */ './filters/InvestorKeywordSearch'));
const GeoFocusFilter = loadable(() => import(/* webpackChunkName: "GeoFocusFilter" */ './filters/GeoFocusFilter'));
const RegionsFilter = loadable(() => import(/* webpackChunkName: "RegionsFilter" */ './filters/RegionsFilter'));
const RegionFocusFilter = loadable(() => import(/* webpackChunkName: "RegionsFocusFilter"*/ './filters/RegionFocusFilter'));
const FundingStageFilter = loadable(() => import(/* webpackChunkName: "FundingStageFilter" */ './FundingStageFilter'));
const LastStageRaisedFilter = loadable(() => import(/* webpackChunkName: "LastStageRaisedFilter" */ './LastStageRaisedFilter'));
const AcquirerRegionFilter = loadable(() => import(/* webpackChunkName: "AcquirerRegionFilter" */ './filters/AcquirerRegionFilter'));
const PrimaryIndustryFilter = loadable(() => import(/* webpackChunkName: "PrimaryIndustryFilter" */ './filters/PrimaryIndustryFilter'));
const SubIndustryFilter = loadable(() => import(/* webpackChunkName: "SubIndustryFilter" */ './filters/SubIndustryFilter'));
const SecondaryIndustryFilter = loadable(() => import(/* webpackChunkName: "SecondaryIndustryFilter" */ './filters/SecondaryIndustryFilter'));
const InvestmentDateFilter = loadable(() => import(/* webpackChunkName: "InvestmentDateFilter" */ './filters/InvestmentDateFilter'));
const InvestmentVehicleFilter = loadable(() => import(/* webpackChunkName: "InvestmentVehicleFilter" */ './InvestmentVehicleFilter'));
const NoOfRoundsRaisedFilter = loadable(() => import(/* webpackChunkName: "NoOfRoundsRaisedFilter" */ './NoOfRoundsRaisedFilter'));
const YearsOfExitFilter = loadable(() => import(/* webpackChunkName: "YearsOfExitFilter" */ './YearsOfExitFilter'));
const BreakdownAmountRaisedFilter = loadable(() => import(/* webpackChunkName: "BreakdownAmountRaisedFilter" */ './BreakdownAmountRaisedFilter'));
const LastDateRaisedFilter = loadable(() => import(/* webpackChunkName: "LastDateRaisedFilter" */ './filters/LastDateRaisedFilter'));
const NumberOfRoundsRaisedFilter = loadable(() => import(/* webpackChunkName: "NumberOfRoundsRaisedFilter" */ './filters/NumberOfRoundsRaisedFilter'));
const StartupFundingVerificationFilter = loadable(() => import(/* webpackChunkName: "StartupFundingVerificationFilter" */ './filters/StartupFundingVerificationFilter'));
const InvestorFilter = loadable(() => import(/* webpackChunkName: "InvestorFilter" */ './filters/InvestorFilter'));
const ProgramFilter = loadable(() => import(/* webpackChunkName: "ProgramFilter" */ './ProgramFilter'));
const CityFilter = loadable(() => import(/* webpackChunkName: "CityFilter" */ './CityFilter'));
const JobFilter = loadable(() => import(/* webpackChunkName: "JobFilter" */ './JobFilter'));
const IsFounderFilter = loadable(() => import(/* webpackChunkName: "IsFounderFilter" */ './filters/IsFounderFilter'));
const EntityNameFilter = loadable(() => import(/* webpackChunkName: "EntityNameFilter" */ './EntityNameFilter'));
const NationalityFilter = loadable(() => import(/* webpackChunkName: "NationalityFilter" */ './NationalityFilter'));
const DegreeFilter = loadable(() => import(/* webpackChunkName: "DegreeFilter" */ './DegreeFilter'));
const IsInvestorFilter = loadable(() => import(/* webpackChunkName: "IsInvestorFilter" */ './filters/IsInvestorFilter'));
const NumberOfFundsFilter = loadable(() => import(/* webpackChunkName : "CityFilter" */ './filters/NumberOfFundsFilter'))
const InvestedInTypeFilter = loadable(() => import(/* webpackChunkName: "InvestedInTypeFilter" */ './filters/InvestedInTypeFilter'));
const DashBoardNameInput = loadable(() => import(/* webpackChunkName: "DashBoardNameInput" */ '../../../pages/SavedSearchDashBoard/CreateDashBoard/DashBoardInputName/DashBoardNameInput'));
const InvestmentLocationFilter = loadable(() => import(/* webpackChunkName: "InvestmentLocationFilter" */ './filters/InvestmentLocationFilter'));
const InvestmentRegionFilter = loadable(() => import(/* webpackChunkName: "InvestmentRegionFilter" */ './filters/InvestmentRegionFilter'));
const InvestedInPrimaryIndustryFilter = loadable(() => import(/* webpackChunkName: "InvestedInPrimaryIndustryFilter" */ './filters/InvestedInPrimaryIndustryFilter'));
const InvestmentFundingStageFilter = loadable(() => import(/* webpackChunkName: "InvestmentFundingStageFilter" */ './filters/InvestmentFundingStageFilter'));

export const filters_data = {
    ecosystem: [
        {
            filters: [{
                KeywordSearch
            }]
        },
        {
            name: 'Demographics',
            filters: [{
                RegionsFilter,
                LocationFilter,
                IndustryFilter,
                TypeFilter,
                DevStageFilter,
                NumberOfFundsFilter
            }]
        },
        {
            name: 'Investment Demographics',
            filters: [{
                InvestmentRegionFilter,
                InvestmentLocationFilter,
                InvestedInPrimaryIndustryFilter,
                InvestmentFundingStageFilter,
            }]
        },
        {
            name: 'Company Focus',
            filters: [{
                RegionFocusFilter,
                GeoFocusFilter,
                InvestedInTypeFilter
            }]
        },
        {
            name: 'Company Status',
            filters: [{
                ClaimFilter,
                NoOfStartupsFilter
            }]
        }
    ],
    startups: [
        {
            filters: [{
                KeywordSearch,
                InvestorFilter,
                ProgramFilter
            }]
        },
        {
            name: 'Demographics',
            filters: [{
                RegionsFilter,
                LocationFilter,
                CityFilter,
                PrimaryIndustryFilter,
                SecondaryIndustryFilter,
                BusinessTypeFilter,
                GenderFilter
            }]
        },
        {
            name: 'Funding Information',
            filters: [{
                BreakdownAmountRaisedFilter,
                TotalAmountRaisedFilter,
                LastDateRaisedFilter,
                InvestmentDateFilter,
                FundedFilter,
                NumberOfRoundsRaisedFilter,
                LastStageRaisedFilter,
                FundingStageFilter,
                InvestmentVehicleFilter,
                StartupFundingVerificationFilter
            }]
        },
        {
            name: 'General Information',
            filters: [{
                DateFilter,
                EmployeeFilter,
                RevenueFilter,
                DevStageFilter,
            }]
        },
        {
            name: 'Company Information',
            filters: [{
                ActiveFilter,
                ClaimFilter
            }]
        }
    ],
    fundingRounds: [
        {
            filters: [{
                KeywordSearch,
                InvestorKeywordSearch
            }]
        },
        {
            name: 'Demographics',
            filters: [{
                RegionsFilter,
                LocationFilter,
                PrimaryIndustryFilter,
                TypeFilter,
                InvestmentVehicleFilter,
            }]
        },
        {
            name: 'Funding Information',
            filters: [{
                InvestmentDateFilter,
                LastDateRaisedFilter,
                NoOfRoundsRaisedFilter,
                BreakdownAmountRaisedFilter,
                TotalAmountRaisedFilter,
                FundingStageFilter
            }]
        },
        {
            name: 'Funding Status',
            filters: [{
                FundingVerificationFilter
            }]
        }
    ],
    exits: [
        {
            filters: [{
                KeywordSearch
            }]
        },
        {
            name: 'Demographics of Invested Company',
            filters: [{
                RegionsFilter,
                LocationFilter,
                PrimaryIndustryFilter
            }]
        },
        {
            name: 'Acquirer Demographics',
            filters: [{
                InvestorFilter,
                AcquirerRegionFilter,
                AcquirerLocationFilter
            }]
        },
        {
            name: 'Acquisition Information',
            filters: [{
                NoOfRoundsRaisedFilter,
                YearsOfExitFilter,
                InvestmentDateFilter,
                BreakdownAmountRaisedFilter,
                TotalAmountRaisedFilter,
                FundingVerificationFilter
            }]
        }
    ],
    funds: [
        {
            filters: [{
                KeywordSearch
            }]
        },
        {
            name: 'Fund Manager Demographics',
            filters: [{
                RegionsFilter,
                LocationFilter,
                IndustryFilter,
                TypeFilter
            }]
        },
        {
            name: 'Funding Information',
            filters: [{
                BreakdownAmountRaisedFilter,
                InvestmentDateFilter,
                FundingVerificationFilter
            }]
        }
    ],
    people: [
        {
            filters: [{
                KeywordSearch,
                EntityNameFilter
            }]
        },
        {
            name: 'Demographics',
            filters: [{
                LocationFilter,
                GenderFilter,
                JobFilter,
                NationalityFilter,
                IsFounderFilter,
                IsInvestorFilter
            }]
        },
        {
            name: 'Education',
            filters: [{
                DegreeFilter
            }]
        }
    ],
    privateEquity: [
        {
            filters: [{
                KeywordSearch
            }]
        },
        {
            name: 'Demographics',
            filters: [{
                RegionsFilter,
                LocationFilter,
                CityFilter,
                CompanyTypeFilter
            }]
        },
        {
            name: 'Funding Information',
            filters: [{
                InvestmentVehicleFilter,
                InvestmentDateFilter,
                StartupFundingVerificationFilter
            }]
        }
    ]
}

// reusable filters header for dir table and filters modal
class FiltersTitle extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate = () => {
        this.initiateTooltip();
    }

    // initialize new tooltips that were added to the dom
    initiateTooltip = () => {
        if (typeof window.jQuery !== 'undefined') {
            $(function () {
                if (document.querySelectorAll('[data-toggle="tooltip"]') && $?.fn?.tooltip) {
                    $('[data-toggle="tooltip"]').tooltip();
                }
            })
        }
    }

    renderClearBtn = () => {
        const { props } = this.props;
        let { numoffilter, clearFilter, currentTab, directory, isEntityRelation } = props;

        // to remove clear filter button when on startup directory chart view and landscape view as funding_verification_status is always true
        const isFundingVerifiedStatusFilter = numoffilter === 1 && directory === 'startups' && (currentTab === 'LandscapeView' || currentTab === 'ChartView')

        // to remove clear filter button when on client directory entity_relation is always true
        let isClientDirectory = directory === 'startups' && isEntityRelation;
        let oneDefaultFilter = numoffilter === 1 && (currentTab === 'TableView' || currentTab === 'FundingView');
        let twoDefaultFilters = numoffilter === 2 && (currentTab === 'LandscapeView' || currentTab === 'ChartView');
        const isEntityRelationFilter = (oneDefaultFilter || twoDefaultFilters) && isClientDirectory;

        if (numoffilter > 0) {
            return (
                <>
                    {(!isFundingVerifiedStatusFilter && !isEntityRelationFilter) &&
                        <a className={`btn btn-main-outline btn-sm clear-all-btn ${CLEAR_ALL_FILTER_BTN_CLASSNAME}`} onClick={event => clearFilter(event)}>
                            <i className={`fa fa-times-circle ${CLEAR_ALL_FILTER_BTN_CLASSNAME}`}></i>
                            Clear
                            {numoffilter > 0 && <span> ({numoffilter})</span>}
                        </a>
                    }
                </>
            )
        }
    }

    renderSelectedFilters = () => {

        const { props } = this.props;
        let { tempFilter, numoffilter, currentTab, directory, passedState } = props;
        let str = [];
        const hasFundingDefaultFilter = directory === 'startups' && (currentTab === 'LandscapeView' || currentTab === 'ChartView');
        const ecosystemLandscapeView = (currentTab === 'LandscapeView' && directory === 'ecosystem');

        if (hasFundingDefaultFilter) {
            str.push({ key: "funding_verification_status", content: "Verified" });
        }

        if (ecosystemLandscapeView) {
            tempFilter = { ...tempFilter, basic: [], investedInTypeFilter: [] };
        }

        if ((!numoffilter || numoffilter === 0) && (!hasFundingDefaultFilter || ecosystemLandscapeView)) {
            return (
                <span>none</span>
            )
        } else {

            // fields using range slider (not for $ amount)
            const range_slider = ["employee", "startup", "roundsRaised", "yearsOfExit"];

            // display the user's selected filters in a text string
            Object.keys(tempFilter).forEach((key, index) => {

                const data = tempFilter[key];

                /* handle custom values / custom fields */

                if (key === "funding_verification_status") {
                    // data === 1 will handle saved searches with 0/1 format instead of boolean
                    if (!hasFundingDefaultFilter && (data === true || (Array.isArray(data) && data.includes('1')))) {
                        str.push({ key, content: "Verified" });
                    }
                }

                // array format
                else if (Array.isArray(data) && data.length > 0) {

                    let content = this.makeListTooltip(data);

                    // checkbox
                    if (key === "verification_status") {
                        content = "Verified Funding";
                    }

                    if (key === "program") {
                        const programData = passedState?.selectedMultiTreeprogram
                        content = this.makeListTooltip(programData);
                    }

                    str.push({ key, content })

                }

                // str format
                else if (typeof data === 'string' && data !== "") {
                    let content = data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
                    str.push({ key, content });
                }

                // obj format (used for ranges)
                else if (typeof data === 'object') {

                    // amount raised with thousands/millions dropdown
                    const dollarAmountFields = ["totalamount", "breakdownamount", 'amountraised'];

                    if (dollarAmountFields.includes(key)) {
                        let { min, max } = data;
                        if (min) {
                            min += props[key + "MinSuffix"];
                        }
                        if (max) {
                            max += props[key + "MaxSuffix"];
                        }

                        let content = "";
                        if (min && max) {
                            content = amountFormatter(min) + " - " + amountFormatter(max);
                        } else if (min) {
                            content = "Above " + amountFormatter(min);
                        } else if (max) {
                            content = "Below " + amountFormatter(max);
                        }

                        if (min || max) {
                            str.push({ key, content });
                        }
                    }

                    // number slider, date input
                    else if (data.hasOwnProperty('from') || data.hasOwnProperty('to') || range_slider.includes(key)) {

                        if (data.from || data.to) {

                            let is_date = false;

                            if (data.from) {
                                is_date = data.from instanceof Date && !isNaN(data.from);
                            } else if (data.to) {
                                is_date = data.to instanceof Date && !isNaN(data.to);
                            }

                            if (is_date) {
                                const format_str = 'YYYY/MM/DD';
                                let content = '';

                                if (data.from && data.to) {
                                    content = moment(data.from).format(format_str) + " - " + moment(data.to).format(format_str);
                                } else if (data.from) {
                                    content = moment(data.from).format(format_str);
                                } else if (data.to) {
                                    content = moment(data.to).format(format_str);
                                }

                                str.push({ key, content });
                            } else {
                                let content = '';

                                if (data.from && data.to) {
                                    content = data.from + " - " + data.to;
                                } else if (data.from) {
                                    content = data.from;
                                } else if (data.to) {
                                    content = data.to;
                                }

                                str.push({ key, content });
                            }
                        } else if (data.hasOwnProperty('min') && (data.hasOwnProperty('max'))) {
                            let content = data.min + " - " + data.max;

                            // setting the displayed value when user selects the maximum number of employees from the options.
                            if (key === 'employee') {
                                const highestOption = numOfEmployees[numOfEmployees.length - 1]
                                if (content === `${highestOption.minValue} - ${highestOption.minValue}`) {
                                    content = `${highestOption.name}`
                                }
                            }
                            str.push({ key, content });
                        }
                    }

                    // min max slider
                    else if (data.hasOwnProperty('min') && (data.hasOwnProperty('max'))) {
                        let content = amountFormatter(data.min) + " - " + amountFormatter(data.max);
                        str.push({ key, content });
                    }
                }

                else if (typeof data === "boolean" && data === true) {
                    str.push({ key, content: "Yes" });
                }

            })

            return (
                <span>
                    {str.map((e, ind) => {
                        let x = e.content;

                        // use the key values as a label
                        // todo can do this a better way
                        let filter_key = e.key.replaceAll('_', ' ');
                        if (filter_key === "location") {
                            if (props.directory === "startups") {
                                filter_key = "headquartered";
                            } else if (props.directory === "ecosystem" || props.directory === "private-equity") {
                                filter_key = "locations";
                            } else if (props.directory === "funds") {
                                filter_key = "investor hq";
                            }
                        } else if (filter_key === "sec industry") {
                            filter_key = "secondary industry";
                        } else if (filter_key === "type") {
                            if (props.directory === "startups") {
                                filter_key = "business type";
                            } else if (props.directory === "ecosystem") {
                                filter_key = "investor type";
                            } else if (props.directory === "funds") {
                                filter_key = "funds manager type";
                            }
                        } else if (filter_key === "stage") {
                            filter_key = "development stage";
                        } else if (filter_key === "gender") {
                            filter_key = props.directory === 'people' ? "gender" : "founder gender";
                        } else if (filter_key === "raise") {
                            filter_key = "looking to raise";
                        } else if (filter_key === "revenue") {
                            filter_key = "revenue generating";
                        } else if (filter_key === "totalamount") {
                            filter_key = "total amount raised";
                        } else if (filter_key === "amountraised") {
                            filter_key = "amount raised";
                        } else if (filter_key === "date") {
                            filter_key = "date founded";
                        } else if (filter_key === "employee") {
                            filter_key = "number of employees";
                        } else if (filter_key === "active") {
                            filter_key = "company status";
                        } else if (filter_key === "basic") {
                            filter_key = "profile verification status";
                        } else if (filter_key === "geoFocusLocation") {
                            filter_key = "geographical focus";
                        } else if (filter_key === "regionFocusLocation") {
                            filter_key = "region focus";
                        } else if (filter_key === "startup") {
                            filter_key = "startups funded";
                        } else if (filter_key === "filter date") {
                            filter_key = "investment date";
                        } else if (filter_key === "breakdownamount") {
                            filter_key = "breakdown amount raised"
                        } else if (filter_key === "yearsOfExit") {
                            filter_key = "years to exit";
                        } else if (filter_key === "job") {
                            filter_key = "Current Job Title";
                        } else if (props.directory === "people" && filter_key === "keyword") {
                            filter_key = "Name";
                        } else if (filter_key === "entityName") {
                            filter_key = "Employer Name";
                        } else if (filter_key === "isFounder") {
                            filter_key = "Founder";
                        } else if (filter_key === "isInvestor") {
                            filter_key = "Investor";
                        } else if (filter_key === "investedInTypeFilter") {
                            filter_key = "invested in type";
                        }

                        // convert camelcase to space separated
                        const regex = /([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g;
                        filter_key = filter_key.replace(regex, '$& ');
                        filter_key = filter_key.toLowerCase();

                        return (
                            <React.Fragment key={`selected-filters-${ind}`}>
                                {/* comma separate for the next filter */}
                                {ind > 0 && ind < str.length ? ", " : ""}

                                <span className="sub-bold selected-filter-item">{filter_key}: </span>
                                <span>{x}</span>
                            </React.Fragment>
                        )

                    })}
                </span>
            )

        }

    }

    // if selected items for a filter > 1, show in tooltip
    makeListTooltip = (data) => {

        // check if array of strings or array of objects
        let selected = [];
        if (String(data[0]) === data[0]) {
            selected = data.map(e => String(e));
        } else {
            selected = data.map(e => String(e.name));
        }

        // display 1st val or show tooltip if 1+
        if (selected.length === 1) {
            return (selected[0]);
        } else {
            const text = selected.map(e => String(e)).join(", ");
            return (
                <span>
                    {selected.length} selected
                    <span data-toggle="tooltip" data-original-title={text} className="selected-filter-tooltip" data-placement="top">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            )
        }

    }

    closePopover = () => {
        // Remove any hover component
        $(".popover").remove();
    }

    render() {

        const { type, confirmationModalClassId } = this.props;
        const isModal = (type === "modal");

        return (
            <>
                <div className="title">

                    {/* filters title triggers modal */}
                    {isModal ?
                        <div className="d-flex">
                            <i className="fa fa-filter" id='filterModelTitle' aria-hidden="true"></i>
                            <h3>Filters</h3>
                        </div>
                        :
                        <div className="d-flex align-items-center mb-2">
                            <a className="d-flex align-items-center" onClick={() => this.closePopover()} data-toggle="modal" data-target="#filterModal">
                                <i className="fa fa-filter" aria-hidden="true"></i>
                                <h3>Filters</h3>
                            </a>
                            {this.renderClearBtn()}
                        </div>
                    }

                    <div>

                        {isModal &&
                            <button
                                type="button"
                                className={`close ${confirmationModalClassId}`}
                                data-dismiss="modal"
                                aria-label="Close"
                                id="filterModalClose"
                            >
                                <span aria-hidden="true" className={confirmationModalClassId}>&times;</span>
                            </button>
                        }

                    </div>
                </div >
                <div className="filters-header">
                    <p>
                        <span className="font-weight-bold">Selected filters - </span>
                        <span>{this.renderSelectedFilters()}</span>
                    </p>
                </div>
            </>
        )

    }
}

export default class SidebarFilters extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { user, directory } = this.props;

        // hide filters if user is logged out and data is hidden
        const dir_login_only = ["funds", 'private-equity'];
        if (!user.is_loggedin && dir_login_only.includes(directory)) {
            return (null);
        }

        // display filters btn
        else {
            return (
                <div className="directory-filters filters">
                    <FiltersTitle props={this.props} />
                </div>
            )
        }

    }
}

class ViewFilters extends Component {

    render() {

        const { passedProps } = this.props;
        const { filterLayoutType, isCreateDashBoard } = passedProps;

        let filters = filters_data[filterLayoutType];
        if (filters == null) {
            filters = filters_data[FILTER_LAYOUT_TYPES.startups];
        }

        if (isCreateDashBoard) {
            const dashBoardInputField = {
                name: 'Dashboard Name',
                filters: [{
                    DashBoardNameInput,
                }]
            }

            if (filters[0].name !== dashBoardInputField.name) {
                filters[0].name = 'Keyword Search';
                filters.unshift(dashBoardInputField)
            }
        }

        let content = filters.map((item, i) =>
            <>
                {(i > 0) &&
                    <hr />
                }

                {(item.name) &&
                    <label className='input-section-description'>{item.name}</label>
                }

                {item.filters.map(filter => {
                    const keys = Object.keys(filter);
                    return keys.map(keyItem => {
                        const Filter = filter[keyItem];
                        return (
                            <div className='dir-filter-input'>
                                <Filter {...passedProps} />
                            </div>
                        )
                    })
                })}
            </>
        )

        return (
            <form className="filter-box" autoComplete="off">
                <div className="filters-container">
                    {content}
                </div>
            </form>
        )

    }
}

/* modal w/ all available filters */
export class FilterModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentFilter: {},
            currentNoOfFiltersSelected: 0,
            confirmationModalClassId: 'openConfirmModel',
            isDefaultFilterApplied: false
        }
    }

    componentDidMount = () => {
        this.updateCurrentFilter()
        document.addEventListener('click', this.handleEvent);
    }

    componentDidUpdate = () => {
        const { passedState } = this.props;
        const { isDefaultFilterApplied } = this.state;

        if (passedState && !passedState.data && !isDefaultFilterApplied && this.isFilterChanged()) {
            this.updateCurrentFilter()
            this.setState({ isDefaultFilterApplied: true })
        }
        this.openLoginModel();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleEvent);
    }

    // trigger login modal if logged out user uses more than x filters
    openLoginModel = () => {
        const { user, numoffilter } = this.props;

        if (!user?.is_loggedin && numoffilter > 1) {

            const filterModalClose = document.getElementById('filterModalClose');
            if (filterModalClose) {
                filterModalClose.click();
            }

            const headerLogin = document.getElementById('headerLogin');
            if (headerLogin) {
                headerLogin.click();
            }

            this.unselectFilters();
        }
    }

    // fetch filtered data
    fetchFilteredData = () => {
        const { filterValues, tempFilter, updateDashboard } = this.props;

        if (!this.isFilterChanged()) return

        this.updateCurrentFilter(true)
        filterValues(tempFilter)

        if (updateDashboard) updateDashboard()
    }

    handleEvent = (e) => {
        const { confirmationModalClassId } = this.state;
        const { isCreateDashBoard, closeDashBoardFilterModal, user, numoffilter } = this.props;

        if (e && e.target.classList.contains(confirmationModalClassId)) {
            if (isCreateDashBoard) {
                closeDashBoardFilterModal()
            } else if (this.isFilterChanged() && (user?.is_loggedin || (!user?.is_loggedin && numoffilter < 2))) {
                $('#confirmationModal').modal('show')
            }
        } else if (e && e.target.classList.contains(CLEAR_ALL_FILTER_BTN_CLASSNAME)) {
            this.updateCurrentFilter(true)
        }
    }

    updateCurrentFilter = (showIsLoading = false) => {
        const { tempFilter, numoffilter } = this.props;

        if (showIsLoading) this.props.setParentState({ data: false })

        this.setState({
            currentFilter: cloneDeep(tempFilter),
            currentNoOfFiltersSelected: cloneDeep(numoffilter)
        })
    }

    isFilterChanged = () => {
        const { tempFilter } = this.props;
        const { currentFilter } = this.state;
        return JSON.stringify(currentFilter) !== JSON.stringify(tempFilter);
    };

    unselectFilters = () => {
        const { currentFilter, currentNoOfFiltersSelected } = this.state;
        const { setParentState } = this.props;

        setParentState({
            tempFilter: cloneDeep(currentFilter),
            numoffilter: cloneDeep(currentNoOfFiltersSelected)
        })
    }

    render() {
        const { confirmationModalClassId } = this.state;
        const { isCreateDashBoard, handleSaveDashBoard, isSavingDashboard, closeDashBoardFilterModal } = this.props;

        return (
            <>
                <div className={`modal fade ${confirmationModalClassId}`} id="filterModal" tabIndex="-1" role="dialog" aria-labelledby="filterModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document" >
                        <div className="modal-content directory-filters">
                            <div className="modal-header">
                                <FiltersTitle
                                    props={this.props}
                                    type="modal"
                                    confirmationModalClassId={confirmationModalClassId}
                                />
                            </div>
                            <div className="modal-body">
                                <ViewFilters passedProps={this.props} />
                            </div>
                            <div className="modal-footer">

                                {isCreateDashBoard ?
                                    <>
                                        <button type="button" className={`btn btn-sm btn-secondary`} onClick={closeDashBoardFilterModal} data-dismiss="modal" >Cancel</button>
                                        <button type="button" className="btn btn-sm btn-main" disabled={isSavingDashboard} onClick={handleSaveDashBoard}>
                                            {isSavingDashboard && <i className="fas fa-circle-notch fa-spin mr-2"></i>} Save
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button type="button" className={`btn btn-sm btn-secondary ${confirmationModalClassId}`} data-dismiss="modal" >Close</button>
                                        <button type="button" className="btn btn-sm btn-main" data-dismiss="modal" onClick={this.fetchFilteredData}>Update</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div >
                </div >
                <ConfirmationModal
                    fetchFilteredData={this.fetchFilteredData}
                    unselectFilters={this.unselectFilters}
                />
            </>
        )
    }
}

const ConfirmationModal = (props) => {
    const { fetchFilteredData, unselectFilters } = props;

    return (
        <div className="modal fade pr-0"
            id="confirmationModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="confirmationModalCenterTitle"
            aria-hidden="true"
            data-backdrop="static"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-align-center" id="confirmationModalLabel">Confirm Filters</h5>
                        <button type="button"
                            className="close"
                            data-dismiss="modal"
                            data-target="#confirmationModal"
                            aria-label="Close"
                            onClick={unselectFilters}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-align-center">
                        The filters you have chosen or removed have not been applied,
                        to apply them click "Apply Filters", otherwise click on "Proceed"
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal" onClick={unselectFilters}>Proceed</button>
                        <button type="button" className="btn btn-main btn-sm" data-dismiss="modal" onClick={fetchFilteredData} >Apply Filters</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
