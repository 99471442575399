import React, { Component } from 'react'
import { connect } from 'react-redux'
import loadable from '@loadable/component';

const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */ './Dashboard'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ './LandingPage'));

class Home extends Component {

    render() {

        const { userState } = this.props;

        return (
            <div>
                {userState && userState.is_loggedin ? <Dashboard /> : <LandingPage />}
            </div>
        )

    }

}


const mapStateToProps = (state) => ({
    user: state.userReducer
});

export default connect(mapStateToProps)(Home)
