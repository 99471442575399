const SENTRY_ORGANIZATION   = 'magnitt-hq';

// for server-side rendering
const SENTRY_PROJECT_SERVER = 'magnitt-react-server-side';
const SENTRY_DSN_SSR = 'https://cbfc34a8859492c608407d074df88f85@o4506931166838784.ingest.us.sentry.io/4507338791190528';

// for client-side rendering
const SENTRY_PROJECT_CLIENT = 'magnitt-react-client-side';
const SENTRY_DSN_CSR = 'https://d978c4da5c16f71274eea456c07cd973@o4506931166838784.ingest.us.sentry.io/4507345525669888';

// errors that should not be tracked in sentry
const SENTRY_ERRORS_TO_IGNORE = [
    /Network Error/i,
    /Request aborted/i, // network issues / timeout / left page
    /Timeout/i,
    // errors from Outlook web crawler 'Safe Links' feature
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/38
    // https://github.com/getsentry/sentry-javascript/issues/3440
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:update, ParamCount:4",
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:simulateEvent, ParamCount:1"
]

module.exports = {
    SENTRY_ORGANIZATION,
    SENTRY_PROJECT_SERVER,
    SENTRY_PROJECT_CLIENT,
    SENTRY_DSN_SSR,
    SENTRY_DSN_CSR,
    SENTRY_ERRORS_TO_IGNORE
};