import { API_URL_NEW } from '../lib/endpoints'
import axios from 'axios';

const apiHelper = {
    /**
    * @param {String}  path             API Path
    * @param {Object}  body             Payload 
    * @param {String}  errorMessage     Message to return on error
    * @returns {Promise}                Promise fulfilling with API returned data
    */
    handlePost: (path, body, errorMessage) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'POST',
                url: `${API_URL_NEW}/${path}`,
                data: body
            };

            axios(config).then((response => {
                resolve(response)
            })).catch(err => {
                if (!errorMessage) errorMessage = err
                reject(errorMessage)
            })
        })
    },

    /**
    * @param {String}  path             API Path
    * @param {Object}  body             Payload 
    * @param {String}  errorMessage     Message to return on error
    * @returns {Promise}                Promise fulfilling with API returned data
    */
    handlePatch: (path, body, errorMessage) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'PATCH',
                url: `${API_URL_NEW}/${path}`,
                data: body
            };

            axios(config).then((response => {
                resolve(response)
            })).catch(err => {
                if (!errorMessage) errorMessage = err
                reject(errorMessage)
            })
        })
    },

    /**
    * @param {String}  path             API Path
    * @param {String}  errorMessage     Message to return on error
    * @returns {Promise}                Promise fulfilling with API returned data
    */
    handleDelete: (path, errorMessage) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: 'DELETE',
                url: `${API_URL_NEW}/${path}`,
            };

            axios(config).then((response => {
                resolve(response)
            })).catch(err => {
                if (!errorMessage)
                    errorMessage = err

                reject(errorMessage)
            })
        })
    },

    /**
    * @param {String}  path             API Path
    * @param {String}  fieldName        FieldName in the backend
    * @param {Blob}    file             File data
    * @param {String}  errorMessage     Message to return on error
    * @returns {Promise}                Promise fulfilling with API returned data
    */
    saveFile: (path, fieldName, file, errorMessage) => {
        const formData = new FormData()
        formData.append(fieldName, file)

        return new Promise((resolve, reject) => {
            axios.post(`${API_URL_NEW}/${path}`,
                formData, {
                    method: 'POST'
                }
            ).then(response => {
                resolve(response)
            }).catch(err => {
                if (!errorMessage)
                    errorMessage = err

                reject(errorMessage)
            })
        })
    },

    /**
     * @param {String}  path        API Path
     * @param {String}  fieldName   FieldName in the backend
     * @param {Blob}    file        File data
     * @param {String}  errorMessage     Message to return on error
     * @returns {Promise}           Promise fulfilling with API returned data
     */
    updateFile: (path, fieldName, file, errorMessage) => {
        const formData = new FormData()
        formData.append(fieldName, file)

        return new Promise((resolve, reject) => {
            axios.patch(`${API_URL_NEW}/${path}`,
                formData, {
                    method: 'PATCH'
                }
            ).then(response => {
                resolve(response)
            }).catch(err => {
                if (!errorMessage) errorMessage = err

                reject(errorMessage)
            })
        })
    }
}

export default apiHelper